import React from "react"
import { Parallax } from "react-parallax"
import { Link } from "gatsby"
import bgImage from "../../assets/images/support.jpg"
import Services from "../../components/Services"
import supportServicesList from "../../constants/support-services-list"
import Seo from "../../components/utilities/Seo"
const ManagementSupport = () => {
  return (
    <section className="management-support">
      <Seo title="運営支援" />
      <Parallax
        bgImage={bgImage}
        bgImageAlt="shopify"
        strength={400}
        blur={{ min: 0, max: 1 }}
      >
        <div className="parallax-box">
          <div className="parallax-info">
            <h1>運営支援</h1>
            <h2>Shopify・ホームページ等運営支援</h2>
            <p>
              オンラインストアやホームページを作ったけど、その後のうまく活用できていない。まかせられるスタッフがいないなどありましたら運営のお手伝いをいたします。
            </p>
            <div>
              <Link to="/contact" className="btn-primary">
                お問合せ
              </Link>
            </div>
          </div>
        </div>
      </Parallax>
      <Services servicesList={supportServicesList} />
    </section>
  )
}

export default ManagementSupport
