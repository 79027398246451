import React from "react"
import { SiShopify } from "react-icons/si"
import { FaFileCode } from "react-icons/fa"
import { BiConversation } from "react-icons/bi"
const supportServicesList = [
  {
    icon: <SiShopify />,
    title: "Shopify運営",
    description:
      "Shopifyオンラインストアの運営のお手伝いいたします。ストアカスタマイズ等。Shopifyの日本法人ShopifyJapanによるBC修了認定済のShopifyパートナー。",
  },
  {
    icon: <FaFileCode />,
    title: "ウェブサイト更新",
    description:
      "ごめんどうなウェブサイトの更新などをいたします。ブログなどの更新。Wordpres・Shopify等。",
  },
  {
    icon: <BiConversation />,
    title: "コンサルティング",
    description:
      "オンラインストア運営・オンラインマーケティングのご相談などを行います。",
  },
]

export default supportServicesList
